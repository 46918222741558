// ======================================================================
// Slate Type Stacks Config | hello@hashandsalt.com
// ======================================================================

// Simple Times Font Stack
$times: Times, 'Times New Roman', serif;
// Simple Arial Font Stack
$arial: Arial, sans-serif;
// Simple Georgia Font Stack
$georgia: Georgia, serif;
// Simple Garamond Font Stack
$garamond: 'Apple Garamond', 'ITC Garamond Narrow', 'Garamond', serif;
// Simple Helvetica Font Stack
$helvetica: 'Helvetica Neue', Helvetica, sans-serif;
// Simple Verdana Font Stack
$verdana: 'Verdana Ref', Verdana, sans-serif;
// Simple Trebuchet Font Stack
$trebuchet: 'Trebuchet MS', sans-serif;
// Simple Gill Sans Font Stack
$gillsans: 'Gill Sans MT', 'Gill Sans', Tahoma, Geneva, sans-serif;
// Full Times Font Stack
$stimes: Cambria, 'Hoefler Text', Utopia, 'Liberation Serif', 'Nimbus Roman No9 L Regular', 'Times New Roman', Times, serif;
// Full Georgia Font Stack
$sgeorgia: Constantia, 'Lucida Bright', Lucidabright, 'Lucida Serif', Lucida, 'DejaVu Serif', 'Bitstream Vera Serif', 'Liberation Serif', Georgia, serif;
// Full Garamond Font Stack
$sgaramond: 'Palatino Linotype', Palatino, Palladio, 'URW Palladio L', 'Book Antiqua', Baskerville, 'Bookman Old Style', 'Bitstream Charter', 'Nimbus Roman No9 L', 'Apple Garamond', 'ITC Garamond Narrow', 'New Century Schoolbook', 'Century Schoolbook', 'Century Schoolbook L', Garamond, serif;
// Full Helvetica Font Stack
$shelvetica: Frutiger, 'Frutiger Linotype', Univers, Calibri, 'Gill Sans', 'Gill Sans MT', 'Myriad Pro', Myriad, 'DejaVu Sans Condensed', 'Liberation Sans', 'Nimbus Sans L', Tahoma, Geneva, 'Helvetica Neue', Helvetica, sans-serif;
// Full Verdana Font Stack
$sverdana: Corbel, 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', 'DejaVu Sans', 'Bitstream Vera Sans', 'Liberation Sans', 'Verdana Ref', Verdana, sans-serif;
// Full Trebuchet Font Stack
$strebuchet: 'Segoe UI', Candara, 'Bitstream Vera Sans', 'DejaVu Sans', 'Bitstream Vera Sans', 'Trebuchet MS', Trebuchet, sans-serif;
// Full Gill Sans Font Stack
$sgillsans: Frutiger, 'Frutiger Linotype', Univers, Calibri, 'Myriad Pro', Myriad, 'DejaVu Sans Condensed', 'Liberation Sans', 'Nimbus Sans L', Tahoma, Geneva, 'Gill Sans MT', 'Gill Sans', sans-serif;
// Terminal / Monospace font
$terminal: Monaco, 'Lucida Sans Typewriter', Consolas, 'Courier New', monospace;
