// ======================================================================
// 06-components - Archive
// ======================================================================

.archive {
  width: 96%;
  max-width: rem(760px);
  margin: 0 auto;
  margin-top: rem(48px);
  margin-bottom: rem(96px);

  &:last-child {
    margin-bottom: rem(96px);
  }
}

.archive-title {
  text-align: center;
}

.archive-text {
  text-align: center;
  font-style: italic;
  margin-bottom: rem(48px);
}

.archive-subtitle {
  display: flex;
  align-items: center;
  margin-top: rem(24px);
}
.archive-latest-subtitle {
  text-align: center;
  display: block;
}
.archive-year {
  @include type-free(21px, 24px, 8px, 24px, true);
}
.archive-month {
  @include type-free(21px, 24px, 8px, 24px, true);
  margin-left: auto;
}
.archive-post-link {
  transition: color 300ms ease-out;
  display: flex;
  align-items: baseline;
  position: relative;
  &:hover {
    color: $jet;
  }
}

.archive-post-link-current {
  font-style: italic;
}
.archive-post-name {
  @include type-free(18px, 0, 18 * 1.5px, 18 * 1.5px, true);
  flex: 0 1 auto;
}
.archive-post-spacer {
  margin: 0 rem(12px) 0 rem(12px);
  height: 1px;
  border: none;
  border-bottom: 1px solid rgba($jet, 0.1);
  flex: 1 0 rem(12px);
  // opacity: 0.5;
}
.archive-post-day {
  @include type-free(18px, 0, 18 * 1.5px, 18 * 1.5px, true);
  flex: 0 1 auto;
}
details {
  text-align: center;
  &[open] summary {
    display: none;
  }
}

summary {
  font-style: italic;
  padding: 0.25rem 0.75rem;
  display: inline-block;
  margin-top: rem(24px);
  border: 1px solid rgba($jet, 0.1);
  transition: all 300ms linear;
  cursor: pointer;
  &:hover {
    color: $jet;
    border: 1px solid $jet;
  }
}
