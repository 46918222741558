// ======================================================================
// 06-components - Site Header
// ======================================================================

.header {
  @include container;
  display: grid;
  grid-template-columns: 1fr 2fr;
  @include media("<medium") {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-rows: auto;
  }
  padding: rem(24px) 0;
  align-items: center;
  pointer-events: all;
}
