// ======================================================================
// 06-components - Footer
// ======================================================================


footer {
  // border-top: 1px dotted $jet;

}

.footer-inner {
  @include container;
  padding: rem(24px) 0;

  .menu-list {

  }

  .copy {
    text-align: center;
 
    @include media("<medium") {
      text-align: center;
    }
  }


  p,
  a {
    @include type-free($base-font-size, 0, 0px, 24px, true);
  


  }

}










