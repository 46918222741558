// ======================================================================
// 05-objects - Logo
// ======================================================================

.logo {
  a {
    display: block;
    width: rem(244px);
    height: auto;
  }


  @include media("<medium") {
    margin: 0 auto rem(24px) auto;
  }
}
