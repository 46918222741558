// ======================================================================
// Slate Colors | hello@hashandsalt.com
// ======================================================================

/// Monochrome - Bright
$bright: #fff;
/// Monochrome - Medium
$gray: #858585;
/// Monochrome - Dark
$dark: #454545;
/// Monochrome - Black
$jet: #000;
/// Monochrome - Charcoal
$charcoal: #202020;

/// Palette - Primary
$primary: #f53580;
/// Palette - Secondary
$secondary: #96def0;
/// Palette - Secondary
$tertiary: #8e44ad;

/// Feedback - Positive
$positive: #27ae60;
/// Feedback - Negative
$negative: #e74c3c;
/// Feedback - Warning
$warning: #f39c12;
// Feedback - Disabled
$disabled: #adadad;

/// Gradient - Start
$gradient-start: $primary;
/// Gradient - Middle
$gradient-middle: darken($primary, 20%);
/// Gradient - End
$gradient-end: darken($primary, 40%);

