// ======================================================================
// 04-elements - Body
// ======================================================================

body {
  padding: 0;
  margin: 0;
  // background-color: #eee;
  height: 100%;
  overflow-x: hidden;
}
