:root {
  --base-font-size: 18px;

  --transition-duration: 0.3s;
  --transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);

  --zoom-icon-background: hsla(0, 0%, 18%, 0.94);
  --zoom-icon-color: #fff;

  --lightbox-background: hsla(0, 0%, 13%, 0.94);
  --lightbox-z-index: 1337;

  --caption-background: rgba(255, 255, 255, 0.94);
  --caption-color: hsl(0, 0%, 21%);

  --counter-background: transparent;
  --counter-color: #fff;

  --button-background: transparent;
  --button-color: #fff;

  --loader-color: #fff;

  --slide-max-height: 85vh;
  --slide-max-width: 85vw;
}
