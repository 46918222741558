// ======================================================================
// 06-components - Site Header
// ======================================================================

.content-editorial {
  width: 96%;
  max-width: rem(760px);
  margin: 0 auto;
  p {
    @include type-free(18px, 0, 18 * 1.5px, 18 * 1.5px, true);
  }

  p:first-of-type {
    @include type-free(26px, 0, 26 * 1.5px, 26 * 1.5px, true);
  }

  figure {
    margin-bottom: rem(24px);
    line-height: 0;
    a {
      display: block;
    }
  }


  .video {
    display: grid;
    justify-content: center;
    @include minbreak($bp-medium) {
      width: auto;
      margin: 0 calc(-50vw + 50%) rem(48px);
    }
  }

  figcaption {
    background-color: $charcoal;
    color: $bright;
    padding: rem(24px);
    line-height: 1.5;
    font-style: italic;
  }
}

// Drop Caps

.content-editorial {
  .box,
  figure,
  h2,
  h2,
  h3,
  h4,
  h6 {
    + p::first-letter {
      color: $charcoal;
      float: left;
      font-family: Georgia;
      font-size: rem(44px);
      line-height: rem(44px);
      padding-right: rem(8px);
      padding-top: rem(4px);
    }
  }
}

// Boxes

.box {
  margin: 0 0 rem(24px);
  background-color: $charcoal;
  box-sizing: border-box;
  figure {
    margin-bottom: 0;
  }
}

.extra-left {
  @include minbreak($bp-xxl) {
    width: 40%;
    float: left;
    margin-left: calc(-40% - 48px);
  }
}

.extra-right {
  @include minbreak($bp-xxl) {
    width: 40%;
    float: right;
    margin-right: calc(-40% - 48px);
  }
}

.extra-large-left {
  @include minbreak($bp-xxl) {
    width: 60%;
    float: left;
    margin: 0 rem(24px) rem(24px) calc(-25%);
  }
}

.extra-large-right {
  @include minbreak($bp-xxl) {
    width: 60%;
    float: right;
    margin: 0 calc(-25%) rem(24px) rem(24px);
  }
}

.cover-photo {
  @include minbreak($bp-medium) {
    width: auto;
    margin: 0 calc(-50vw + 50%) rem(48px);
  }
}

.cover-photo-left-edge {
  @include minbreak($bp-medium) {
    width: auto;
    margin: 0 0 rem(48px) calc(-50vw + 50%);
  }
}

.cover-photo-right-edge {
  @include minbreak($bp-medium) {
    width: auto;
    margin: 0 calc(-50vw + 50% + 8px) rem(48px) 0;
  }
}

// Custom Type
.content-editorial {
  blockquote {
    @include minbreak($bp-xxl) {
      width: 60%;
      float: left;
      margin: 0 rem(24px) rem(24px) calc(-25%);
    }
    background-color: #e4e4e4;
    position: relative;
    color: $charcoal;
    padding: rem(52px) rem(24px) rem(24px);
    margin: 0;
    @include type-free(22px, 0, 22 * 1.5px, 22 * 1.5px, true);

    &:before {
      quotes: "" " " "";
      position: absolute;
      top: rem(-32px);
      left: rem(-32px);
      @include maxbreak($bp-medium) {
        left: rem(-12px);
      }
      content: "“";
      z-index: 20;
      @include type-free(220px, 0, 0, 220px, true);
      display: inline;
      color: #6b6b6b;
    }
  }
}
